import { BookState } from "@models/book";
import React, { createContext, useContext, useRef } from "react";
import { useEffect as useBetterEffect } from "react-better-effect";
import { useStore } from "zustand";

import { bookStore } from "./store";

const Context = createContext<ReturnType<typeof bookStore> | null>(null);

interface BookProviderProps {
  children: React.ReactNode;
}

export const BookProvider = ({ children }: BookProviderProps) => {
  const storeRef = useRef<ReturnType<typeof bookStore>>();

  if (!storeRef.current) {
    storeRef.current = bookStore();
  }

  return (
    <Context.Provider value={storeRef.current}>
      <InitProvider>{children}</InitProvider>
    </Context.Provider>
  );
};

const InitProvider = ({ children }: BookProviderProps) => {
  const deinit = useBook(($) => $.deinit);

  useBetterEffect(
    ($) => () => {
      $.deinit();
    },
    [],
    { deinit }
  );
  return <>{children}</>;
};

export const withBook =
  <P extends object>(Component: React.ComponentType<P>) =>
  (props?: P) =>
    (
      <BookProvider>
        <Component {...(props as P)} />
      </BookProvider>
    );

export function useBook<T = BookState>(selector?: (state: BookState) => T): T {
  const store = useContext(Context);
  if (store === null) {
    throw new Error("useBook must be used within a BookProvider");
  }
  return useStore(
    store,
    selector ?? ((($: any) => $) as unknown as (state: BookState) => T)
  );
}

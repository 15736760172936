import React, { createContext, useContext, useRef } from "react";
import { useEffect as useBetterEffect } from "react-better-effect";
import { useStore } from "zustand";

import { AuthState } from "@models/auth";
import { authStore } from "./store";

const Context = createContext<ReturnType<typeof authStore> | null>(null);

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const storeRef = useRef<ReturnType<typeof authStore>>();

  if (!storeRef.current) {
    storeRef.current = authStore();
  }

  return (
    <Context.Provider value={storeRef.current}>
      <InitProvider>{children}</InitProvider>
    </Context.Provider>
  );
};

const InitProvider = ({ children }: AuthProviderProps) => {
  const deinit = useAuth(($) => $.deinit);

  useBetterEffect(($) => () => $.deinit(), [], { deinit });
  return <>{children}</>;
};

export const withAuth =
  <P extends object>(Component: React.ComponentType<P>) =>
  (props?: P) =>
    (
      <AuthProvider>
        <Component {...(props as P)} />
      </AuthProvider>
    );

export function useAuth<T = AuthState>(selector?: (state: AuthState) => T): T {
  const store = useContext(Context);
  if (store === null) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return useStore(
    store,
    selector ?? ((($: any) => $) as unknown as (state: AuthState) => T)
  );
}

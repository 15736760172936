import {
  AppGolfFilters,
  AppHotelFilters,
  AppState,
  FilterParam,
} from "@models/app";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { persist, createJSONStorage } from "zustand/middleware";

import dayjs from "dayjs";

export const appStore = () =>
  create<AppState>()(
    persist(
      immer((set, get) => ({
        course: null,
        product: null,
        display_currency: {
          id: "",
          name: "",
          symbol: "",
          code: "",
          created_at: new Date(),
        },
        date: dayjs().add(2, "day").format("YYYY-MM-DD"),
        package_date: null,
        showPackageDatePicker: false,
        showFindBookingModal: false,
        showDatePicker: false,
        showPeoplePicker: false,
        showNightPicker: false,
        showCurrencyPicker: false,
        showLoginModal: false,
        currentUrl: "",
        golfers: 2,
        number_of_nights: null,
        room_type: null,
        search: {
          hotel: {
            sort: {
              field: "price",
            },
            search: "",
            filters: {
              rating: [],
              courses: [],
              location: [],
              hotel_location: [],
              hotel_type: [],
              amenities: [],
            },
          },
          package: {
            sort: {
              field: "price_asc",
            },
            filters: {
              courses: [],
            },
          },
          golf: {
            sort: {
              field: "recommended",
            },
            search: "",
            filters: {
              price_range: [],
              rating: [],
              course_location: [],
              popular_filters: [],
              course_type: [],
              technical_difficulty: [],
              amenities: [],
            },
          },
        },
        setGolfSortField: (field: string) => {
          set((state) => {
            state.search.golf.sort.field = field;
          });
        },
        setHotelSortField: (field: string) => {
          set((state) => {
            state.search.hotel.sort.field = field;
          });
        },
        setPackageSortField: (field: string) => {
          set((state) => {
            state.search.package.sort.field = field;
          });
        },
        removePackageFilterByKey: (payload: any) => {
          set((state) => {
            const { key, value } = payload;
            let param = value as FilterParam;

            // get the index of the item using the FilterParam.value for comparison
            let index = state.search.package.filters[key].findIndex(
              (item: FilterParam) => item.value === param.value
            );

            if (index > -1) {
              // remove the item from the array
              state.search.package.filters[key].splice(index, 1);
            }
          });
        },
        removeHotelFilterByKey: (payload: any) => {
          set((state) => {
            const { key, value } = payload;
            let param = value as FilterParam;

            // get the index of the item using the FilterParam.value for comparison
            let index = state.search.hotel.filters[key].findIndex(
              (item: FilterParam) =>
                JSON.stringify(item.value) === JSON.stringify(param.value)
            );

            if (index > -1) {
              // remove the item from the array
              state.search.hotel.filters[key].splice(index, 1);
            }
          });
        },
        removeGolfFilterByKey: (payload: any) => {
          set((state) => {
            const { key, value } = payload;
            let param = value as FilterParam;

            // get the index of the item using the FilterParam.value for comparison
            let index = state.search.golf.filters[key].findIndex(
              (item: FilterParam) =>
                JSON.stringify(item.value) === JSON.stringify(param.value)
            );

            if (index > -1) {
              state.search.golf.filters[key].splice(index, 1);
            }
          });
        },
        setGolfFilters: (filters: AppGolfFilters) => {
          set((state) => {
            state.search.golf.filters = filters;
          });
        },
        setHotelFilters: (filters: AppHotelFilters) => {
          set((state) => {
            state.search.hotel.filters = filters;
          });
        },
        setGolfSearch: (search: string) => {
          set((state) => {
            state.search.golf.search = search;
          });
        },
        setHotelSearch: (search: string) => {
          set((state) => {
            state.search.hotel.search = search;
          });
        },
        resetPackageFilters: () => {
          set((state) => {
            state.search.package.filters = {
              courses: [],
            };
          });
        },
        resetHotelFilters: () => {
          set((state) => {
            state.search.hotel.search = "";
            state.search.hotel.filters = {
              rating: [],
              courses: [],
              hotel_type: [],
              location: [],
            };
          });
        },
        resetGolfFilters: () => {
          set((state) => {
            state.search.golf.search = "";
            state.search.golf.filters = {
              price_range: [],
              rating: [],
              course_location: [],
              popular_filters: [],
              course_type: [],
              technical_difficulty: [],
              amenities: [],
            };
          });
        },
        setApp: (app: any) => {
          set((state) => {
            state.course = app;
          });
        },
        setShowDatePicker: () => {
          set((state) => {
            state.showDatePicker = true;
            state.showNightPicker = false;
            state.showPackageDatePicker = false;
            state.showPeoplePicker = false;
            state.showCurrencyPicker = false;
            state.showLoginModal = false;
            state.showFindBookingModal = false;
          });
        },
        setShowPackageDatePicker: () => {
          set((state) => {
            state.showPackageDatePicker = true;
            state.showDatePicker = false;
            state.showNightPicker = false;
            state.showPeoplePicker = false;
            state.showCurrencyPicker = false;
            state.showLoginModal = false;
            state.showFindBookingModal = false;
          });
        },
        setShowNightPicker: () => {
          set((state) => {
            state.showPackageDatePicker = false;
            state.showDatePicker = false;
            state.showNightPicker = true;
            state.showPeoplePicker = false;
            state.showCurrencyPicker = false;
            state.showLoginModal = false;
            state.showFindBookingModal = false;
          });
        },
        setShowCurrencyPicker: () => {
          set((state) => {
            state.showCurrencyPicker = true;
            state.showPeoplePicker = false;
            state.showNightPicker = false;
            state.showPackageDatePicker = false;
            state.showDatePicker = false;
            state.showLoginModal = false;
            state.showFindBookingModal = false;
          });
        },
        setShowPeoplePicker: () => {
          set((state) => {
            state.showDatePicker = false;
            state.showPeoplePicker = true;
            state.showCurrencyPicker = false;
            state.showNightPicker = false;
            state.showPackageDatePicker = false;
            state.showLoginModal = false;
            state.showFindBookingModal = false;
          });
        },
        setShowLoginModal: () => {
          set((state) => {
            state.showDatePicker = false;
            state.showPeoplePicker = false;
            state.showCurrencyPicker = false;
            state.showNightPicker = false;
            state.showPackageDatePicker = false;
            state.showFindBookingModal = false;
            state.showLoginModal = true;
            state.currentUrl = window !== undefined ? window.location.href : "";
          });
        },
        setShowFindBookingModal: () => {
          set((state) => {
            state.showDatePicker = false;
            state.showPeoplePicker = false;
            state.showCurrencyPicker = false;
            state.showNightPicker = false;
            state.showPackageDatePicker = false;
            state.showFindBookingModal = true;
            state.showLoginModal = false;
            state.currentUrl = window !== undefined ? window.location.href : "";
          });
        },
        hideDatePicker: () => {
          set((state) => {
            state.showDatePicker = false;
          });
        },
        hidePackageDatePicker: () => {
          set((state) => {
            state.showPackageDatePicker = false;
          });
        },
        hideNightPicker: () => {
          set((state) => {
            state.showNightPicker = false;
          });
        },
        hideFindBookingModal: () => {
          set((state) => {
            state.showFindBookingModal = false;
          });
        },
        hidePeoplePicker: () => {
          set((state) => {
            state.showPeoplePicker = false;
          });
        },
        hideLoginModal: () => {
          set((state) => {
            state.showLoginModal = false;
          });
        },
        hideModals: () => {
          set((state) => {
            state.showDatePicker = false;
            state.showPeoplePicker = false;
            state.showPackageDatePicker = false;
            state.showFindBookingModal = false;
            state.showNightPicker = false;
            state.showCurrencyPicker = false;
            state.showLoginModal = false;
          });
        },
        setCourse: (course: any) => {
          set((state) => {
            state.course = course;
          });
        },
        setProduct: (product: any) => {
          set((state) => {
            state.product = product;
          });
        },
        setCurrency: (currency: any) => {
          set((state) => {
            state.display_currency = currency;
          });
        },
        addGolfer: () => {
          set((state) => {
            if (state.golfers < 16) {
              state.golfers += 1;
            }
          });
        },
        setGolfers: (golfers: number) => {
          set((state) => {
            state.golfers = golfers;
          });
        },
        setNights: (nights: number) => {
          set((state) => {
            state.number_of_nights = nights;
          });
        },
        setRoomType: (room_type: string) => {
          set((state) => {
            state.room_type = room_type;
          });
        },
        setPackageDate: (date: string) => {
          set((state) => {
            state.package_date = date;
          });
        },
        setDate: (date: string) => {
          set((state) => {
            state.date = date;
          });
        },
        removeGolfer: () => {
          set((state) => {
            if (state.golfers > 1) state.golfers -= 1;
          });
        },
        setCurrentUrl: (url: string) => {
          set((state) => {
            state.currentUrl = url;
          });
        },
        deinit: () => {
          set((state) => {});
        },
      })),
      {
        name: "golfsavers-app-storage",
        storage: createJSONStorage(() => localStorage),
      }
    )
  );

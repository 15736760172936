exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-account-bookings-tsx": () => import("./../../../src/pages/account/bookings.tsx" /* webpackChunkName: "component---src-pages-account-bookings-tsx" */),
  "component---src-pages-account-favourites-tsx": () => import("./../../../src/pages/account/favourites.tsx" /* webpackChunkName: "component---src-pages-account-favourites-tsx" */),
  "component---src-pages-account-index-tsx": () => import("./../../../src/pages/account/index.tsx" /* webpackChunkName: "component---src-pages-account-index-tsx" */),
  "component---src-pages-account-recentlyviewed-tsx": () => import("./../../../src/pages/account/recentlyviewed.tsx" /* webpackChunkName: "component---src-pages-account-recentlyviewed-tsx" */),
  "component---src-pages-authredirect-tsx": () => import("./../../../src/pages/authredirect.tsx" /* webpackChunkName: "component---src-pages-authredirect-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-book-tsx": () => import("./../../../src/pages/book/[...].tsx" /* webpackChunkName: "component---src-pages-book-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-enquiry-submitted-tsx": () => import("./../../../src/pages/enquiry-submitted.tsx" /* webpackChunkName: "component---src-pages-enquiry-submitted-tsx" */),
  "component---src-pages-packages-enquiry-tsx": () => import("./../../../src/pages/packages/enquiry.tsx" /* webpackChunkName: "component---src-pages-packages-enquiry-tsx" */),
  "component---src-pages-packages-index-tsx": () => import("./../../../src/pages/packages/index.tsx" /* webpackChunkName: "component---src-pages-packages-index-tsx" */),
  "component---src-pages-password-reset-tsx": () => import("./../../../src/pages/password-reset.tsx" /* webpackChunkName: "component---src-pages-password-reset-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-rate-enquiry-submitted-tsx": () => import("./../../../src/pages/rate-enquiry-submitted.tsx" /* webpackChunkName: "component---src-pages-rate-enquiry-submitted-tsx" */),
  "component---src-pages-reviews-tsx": () => import("./../../../src/pages/reviews.tsx" /* webpackChunkName: "component---src-pages-reviews-tsx" */),
  "component---src-pages-success-tsx": () => import("./../../../src/pages/success.tsx" /* webpackChunkName: "component---src-pages-success-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-templates-course-index-tsx": () => import("./../../../src/templates/course/index.tsx" /* webpackChunkName: "component---src-templates-course-index-tsx" */),
  "component---src-templates-golf-courses-index-tsx": () => import("./../../../src/templates/golf-courses/index.tsx" /* webpackChunkName: "component---src-templates-golf-courses-index-tsx" */),
  "component---src-templates-golf-packages-index-tsx": () => import("./../../../src/templates/golf-packages/index.tsx" /* webpackChunkName: "component---src-templates-golf-packages-index-tsx" */),
  "component---src-templates-green-fees-index-tsx": () => import("./../../../src/templates/green-fees/index.tsx" /* webpackChunkName: "component---src-templates-green-fees-index-tsx" */),
  "component---src-templates-hotel-index-tsx": () => import("./../../../src/templates/hotel/index.tsx" /* webpackChunkName: "component---src-templates-hotel-index-tsx" */),
  "component---src-templates-region-index-tsx": () => import("./../../../src/templates/region/index.tsx" /* webpackChunkName: "component---src-templates-region-index-tsx" */)
}


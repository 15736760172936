import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

import { persist, createJSONStorage } from "zustand/middleware";
import _ from "lodash";
import { CheckoutState } from "@models/checkout";

export const checkoutStore = () =>
  create<CheckoutState>()(
    persist(
      immer((set, get) => ({
        cart: [],
        addToCart: (payload: any) => {
          set((state) => {
            const cart = _.cloneDeep(state.cart);
            cart.push(payload);
            state.cart = cart;
          });
        },
        removeFromCart: (payload: any) => {
          set((state) => {
            state.cart = state.cart.filter((item: any) => item.id !== payload);
          });
        },
        clearCart: () => {
          set((state) => {
            state.cart = [];
          });
        },
        deinit: () => {
          set((state) => {});
        },
      })),
      {
        name: "golfsavers-checkout-storage",
        storage: createJSONStorage(() => localStorage),
      }
    )
  );

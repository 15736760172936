import React, { createContext, useContext, useRef } from "react";
import { useEffect as useBetterEffect } from "react-better-effect";
import { useStore } from "zustand";

import { CheckoutState } from "@kalabo/golfsavers-common";
import { checkoutStore } from "./store";

const Context = createContext<ReturnType<typeof checkoutStore> | null>(null);

interface CheckoutProviderProps {
  children: React.ReactNode;
}

export const CheckoutProvider = ({ children }: CheckoutProviderProps) => {
  const storeRef = useRef<ReturnType<typeof checkoutStore>>();

  if (!storeRef.current) {
    storeRef.current = checkoutStore();
  }

  return (
    <Context.Provider value={storeRef.current}>
      <InitProvider>{children}</InitProvider>
    </Context.Provider>
  );
};

const InitProvider = ({ children }: CheckoutProviderProps) => {
  const deinit = useCheckout(($) => $.deinit);

  useBetterEffect(($) => () => $.deinit(), [], { deinit });
  return <>{children}</>;
};

export const withCheckout =
  <P extends object>(Component: React.ComponentType<P>) =>
  (props?: P) =>
    (
      <CheckoutProvider>
        <Component {...(props as P)} />
      </CheckoutProvider>
    );

export function useCheckout<T = CheckoutState>(
  selector?: (state: CheckoutState) => T
): T {
  const store = useContext(Context);
  if (store === null) {
    throw new Error("useCheckout must be used within a CheckoutProvider");
  }
  return useStore(
    store,
    selector ?? ((($: any) => $) as unknown as (state: CheckoutState) => T)
  );
}

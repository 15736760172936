import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { persist, createJSONStorage } from "zustand/middleware";
import supabase from "@clients/supabase";
import { AuthState, ILoginProps, ISignupProps } from "@models/auth";
import { invoke } from "@kalabo/golfsavers-common";

const ActiveStates = [
  "NEW_BOOKING",
  "WAITING_FOR_DEPOSIT",
  "CONFIRMING_GOLF",
  "CONFIRMING_TRANSFERS",
  "CONFIRMING_HOTELS",
  "CONFIRMING_CUSTOM",
  "CONFIRMING_ALL",
  "WAITING_FOR_BALANCE",
  "WAITING_FOR_PAYMENT",
  "PAYMENT_OVERDUE",
  "CONFIRMED",
];

const getUserProfile = async (email: string) => {
  const { data, error } = await supabase
    .from("user_profiles")
    .select(
      `id, first_name, last_name, phone, display_currency, country_of_residence, email, user_favourites, created_at, orders!client_id(*, order_lines!order_id(*))`
    )
    .eq("email", email)
    .single();

  return data;
};

export const authStore = () =>
  create<AuthState>()(
    persist(
      immer((set, get) => ({
        loading: false,
        userInfo: {}, // for user object
        userProfile: {}, // for user profile object
        userToken: null, // for storing the JWT
        error: null,
        success: false,
        setLoading: (loading: boolean) => {
          set((state) => {
            state.loading = loading;
          });
        },
        deinit: () => {
          set((state) => {});
        },
        restore: async (payload: any) => {
          const userData = await getUserProfile(payload?.user?.email);

          set((state) => {
            state.userProfile = {
              ...userData,
              orders: userData?.orders.filter((order) => {
                return ActiveStates.includes(order.status);
              }),
              avatar: payload?.user?.user_metadata?.avatar_url,
            };
            state.userInfo = payload?.user;
            state.userToken = payload?.access_token;
          });
        },
        setUser: (user: any) => {
          set((state) => {
            state.userInfo = user;
          });
        },
        setUserProfile: (user: any) => {
          set((state) => {
            state.userProfile = {
              ...user,
            };
          });
        },
        logout: async () => {
          try {
            localStorage.removeItem("SUPABASE_ACCESS_TOKEN");
            supabase.auth.signOut();
          } catch (error) {}
        },
        refreshUser: async (email: string) => {
          const userData = await getUserProfile(email);

          set((state) => {
            state.userProfile = {
              ...userData,
              orders: userData?.orders.filter((order) => {
                return ActiveStates.includes(order.status);
              }),
            };
          });
        },
        login: async ({ email, password }: ILoginProps) => {
          try {
            const {
              data: { user, session },
              error,
            } = await supabase.auth.signInWithPassword({
              email,
              password,
            });
            if (error) {
              set((state) => {
                state.error = error;
                state.loading = false;
              });
            } else {
              const accessToken = session?.access_token || "";
              localStorage.setItem("SUPABASE_ACCESS_TOKEN", accessToken);
              set((state) => {
                state.loading = false;
                state.userInfo = {
                  error: error,
                  session: session,
                  user: user,
                  accessToken: accessToken,
                };
                state.userToken = accessToken;
                state.success = true;
              });
            }
          } catch (e) {
            set((state) => {
              state.error = e;
              state.loading = false;
            });
          }
        },
        clearError: () => {
          set((state) => {
            state.error = null;
          });
        },
        signup: async ({
          email,
          password,
          first_name,
          last_name,
          phone_number,
          country_of_residence,
          join_mailing_list,
          display_currency,
        }: ISignupProps) => {
          try {
            set((state) => {
              state.loading = true;
            });

            const response = await invoke("createUser", {
              email,
              password,
              first_name,
              last_name,
              phone_number,
              country_of_residence,
              join_mailing_list,
              display_currency,
            });

            if (response.status !== 200) {
              set((state) => {
                state.error = response.body;
                state.loading = false;
              });
            } else {
              const {
                data: { user, session },
                error,
              } = await supabase.auth.signInWithPassword({
                email,
                password,
              });
              if (error) {
                set((state) => {
                  state.error = error;
                  state.loading = false;
                });
              } else {
                let user_data = await getUserProfile(email);
                const accessToken = session?.access_token || "";
                localStorage.setItem("SUPABASE_ACCESS_TOKEN", accessToken);
                set((state) => {
                  state.userProfile = {
                    ...user_data,
                    orders: user_data?.orders.filter((order) => {
                      return ActiveStates.includes(order.status);
                    }),
                    avatar: payload?.user?.user_metadata?.avatar_url,
                  };
                  state.loading = false;
                  state.userInfo = {
                    error: error,
                    session: session,
                    user: user,
                    accessToken: accessToken,
                  };
                  state.userToken = accessToken;
                  state.success = true;
                });
              }
            }
          } catch (e) {
            set((state) => {
              state.error = e;
              state.loading = false;
            });
          }
        },
      })),
      {
        name: "golfsavers-auth-storage",
        storage: createJSONStorage(() => localStorage),
      }
    )
  );

import "./src/styles/global.css";
import React from "react";
import { ConfigProvider } from "antd";
import Root from "./src/components/layout/root";

export const wrapRootElement = ({ element }) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Badge: {
            textFontWeight: "600",
            textFontSize: 10,
            textFontSizeSM: 10,
          },
        },
        token: {
          fontFamily: "Poppins, sans-serif",
        },
      }}
    >
      <Root>{element}</Root>
    </ConfigProvider>
  );
};

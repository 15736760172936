import React, { createContext, useContext, useRef } from "react";
import { useEffect as useBetterEffect } from "react-better-effect";
import { useStore } from "zustand";

import { appStore } from "./store";
import { AppState } from "@models/app";

const Context = createContext<ReturnType<typeof appStore> | null>(null);

interface AppProviderProps {
  children: React.ReactNode;
}

export const AppProvider = ({ children }: AppProviderProps) => {
  const storeRef = useRef<ReturnType<typeof appStore>>();

  if (!storeRef.current) {
    storeRef.current = appStore();
  }

  return (
    <Context.Provider value={storeRef.current}>
      <InitProvider>{children}</InitProvider>
    </Context.Provider>
  );
};

const InitProvider = ({ children }: AppProviderProps) => {
  const deinit = useApp(($) => $.deinit);

  useBetterEffect(($) => () => $.deinit(), [], { deinit });
  return <>{children}</>;
};

export const withApp =
  <P extends object>(Component: React.ComponentType<P>) =>
  (props?: P) =>
    (
      <AppProvider>
        <Component {...(props as P)} />
      </AppProvider>
    );

export function useApp<T = AppState>(selector?: (state: AppState) => T): T {
  const store = useContext(Context);
  if (store === null) {
    throw new Error("useApp must be used within a AppProvider");
  }
  return useStore(
    store,
    selector ?? ((($: any) => $) as unknown as (state: AppState) => T)
  );
}

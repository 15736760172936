import * as React from "react";
import supabase from "@clients/supabase";
import "react-loading-skeleton/dist/skeleton.css";

import { AppProvider, useApp } from "@store/app";
import { AuthProvider, useAuth, withAuth } from "@store/auth";
import { BookProvider } from "@store/book";
import { CheckoutProvider } from "@store/checkout";
import dayjs from "dayjs";

interface IProps {
  children: React.ReactNode;
}

const Root = ({ children }: IProps) => {
  const restore = useAuth(($) => $.restore);
  const userProfile = useAuth(($) => $.userProfile);

  // create a useEffect hook with an async function inside

  React.useEffect(() => {
    const restoreSession = async () => {
      const {
        data: { session },
      } = await supabase.auth.getSession();

      if (session) {
        restore(session);
      }
    };

    restoreSession();

    supabase.auth.onAuthStateChange(async (event, session) => {
      if (session) {
        restore({
          ...(session ?? null),
        });
      } else {
        restore(null);
      }
    });
  }, []);

  return (
    <AppProvider>
      <BookProvider>
        <CheckoutProvider>{children}</CheckoutProvider>
      </BookProvider>
    </AppProvider>
  );
};

export default withAuth(Root);
